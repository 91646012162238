<template>
    <div class="filter-area">
      <!-- 搜索框 -->
      <div class="filter-area-search">
        <div class="right-wrap">
          <div class="search-name">
            <span class="search-name-partner">{{$i18n.t('channel.partnerName')}}</span>
          </div>
          <div class="search-bar">
            <input class="search-input" type="text" :placeholder="$i18n.t('channel.searchPlaceholder')" @keyup.enter="search" v-model="keyWords">
            <i class="el-icon-search search-icon" @click="search"/>
          </div>
        </div>
      </div>
      
      <!-- pc与手机端适配 一级过滤-->
      <!-- 手机 -->
      <el-row v-if="setEleRowViewShow" :gutter="20">
        <el-col :span="6">
          <div class="grid-content bg-purple" @click="toggleShow('country')" :title="$i18n.t('channel.countryOrRegion')">
            <span>{{$i18n.t('channel.countryOrRegion')}}</span>
            <span :class="[!show_area?'delta':'delta—top']"></span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="grid-content bg-purple" @click="toggleShow('partner')" :title="$i18n.t('channel.partnerType')">
            <span>{{$i18n.t('channel.partnerType')}}</span>
            <span :class="[!show_partner?'delta':'delta—top']"></span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class=""></div>
        </el-col>
      </el-row>
      <!-- pc -->
      <el-row v-else :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple" @click="toggleShow('country')" title="*Country/Region">
            <span>*Country/Region</span>
            <span :class="[!show_area?'delta':'delta—top']"></span>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple" @click="toggleShow('partner')" title="Partner Type">
            <span>PartnerType</span>
            <span :class="[!show_partner?'delta':'delta—top']"></span>
          </div>
        </el-col>
        <el-col :span="0">
          <div class=""></div>
        </el-col>
      </el-row>
  
      <div class="ul-area" v-if="show_area">
        <ul class="sandbar">
          <li v-for="(item,index) in sandbar" :class="{'filterSandbar_active':querySandbar===item.name}" @click="pickSandbar(item.name)" :key="index">
            {{ item.name }}
          </li>
        </ul>
        <hr>
        <ul class="country">
          <li v-for="(item, index) in countrys" :class="{'filterCountry_active':queryCountry===item}" :key="index" @click="pickCountry(item)">
            {{ item }}
          </li>
        </ul>
      </div>
  
      <div class="partner-type" v-if="show_partner">
        <ul class="partner-type-1">
          <li v-for="(item,index) in partners" :key="index" :class="{'filterTopPartner_active':queryTopPartnerType === item.name}" @click="pickPartnerTopType(item.name)">
              {{ item.name }}
          </li>
        </ul>
        <hr>
        <ul class="partner-type-2">
          <li v-for="(item, index) in partnersList" :key="index" :class="{'filterPartner_active':queryPartnerType===item}" @click="pickPartnerType(item)">
            {{ item }}
          </li>
        </ul>
      </div>
  
    </div>
  </template>
  
  <script>
  import {getProductList} from '../../../api/channel';
  import PullDownList from '../../../components/PullDownList';
  import {isEqual, throttle} from "lodash";
  
  export default {
    name: 'FilterChannel',
    components: {},
  
    data() {
      return {
        loading: true,
        radio: 3,
        sandbar: [
          {
            name: 'Africa',
            items: ["Angola", "Côte d'Ivoire", 'Egypt', 'Ghana', 'Kenya', 'Libya', 'Morocco', 'Nigeria', 'The Republic of South Africa', 'Tunisia', 'Uganda', 'Zimbabwe'],
          },
          {
            name: 'Asia Pacific',
            items: ['Bangladesh', "Cambodia", 'Hong Kong SAR China', 'Indonesia', 'Kazakhstan', 'Malaysia',
              'Pakistan', 'Philippines', 'Singapore', 'Thailand', 'Vietnam'],
          },
          {
            name: 'Europe',
            items: ['Greece', 'Hungary', 'Spain', 'Turkey'],
          },
          {
            name: 'Latin America',
            items: ['Brazil', 'Chile', 'Mexico', 'Uruguay'],
          },
          {
            name: 'Middle East',
            items: ['Iraq', 'Kyrgyzstan', 'Kuwait', 'Lebanon', 'Qatar', 'Saudi Arabia', 'United Arab Emirates'],
          },
          {
            name: 'Other',
            items: [],
          },
        ],
        countrys: [],
        partnersList: [],
        is_show_sandbar: true,
        show_area: true,
        show_partner: false,
        partners: [
          {
            name: "Distributor",
            items: ['All', 'Comprehensive Distributor', 'Project-based Distributor', 'Certified Service Partner']
          },
          {
            name: "Reseller",
            items: ['All', 'Commercial Gold Partner', 'Commercial Silver Partner', 'Certified Service Partner']
          },
          {
            name: "System Integrator",
            items: ['All', 'Industry Gold Partner', 'Industry Silver Partner', 'Certified Service Partner']
          }
        ],
        querySandbar: 'Africa',
        queryCountry: null,
        queryTopPartnerType: 'Distributor',
        queryPartnerType: null,
        keyWords: null,
        delta: {
          width: 0,
          height: 0,
          //margin: 20px auto;
          marginLeft: "20px",
          // border: 5px solid transparent;
          border: '5px solid transparent',
          // border-bottom-color: rgba(176, 176, 176, 1);
          borderBottomColor: !this.show_area ? "rgba(176, 176, 176, 1)" : "#FFFFFF",
          // borderTopColor: this.show_area?"rgb(26,24,24)":"#FFFFFF",
        }
      };
    },
  
    // 监听 指计算属性
    computed: {
      setEleRowViewShow() {
        return document.documentElement.clientWidth >= 750;
      }
    },
  
    watch: {

    },
  
    created() {
        this.pickSandbar('Africa')
        this.pickPartnerTopType('Distributor')
    },
    methods: {
        toggleShow(colName) {
            if (colName === 'country') {
                this.show_area = true
                this.show_partner = false
            }
            if (colName === 'partner') {
                this.show_area = false
                this.show_partner = true
            }
        },
        pickSandbar(item) {
            this.countrys = this.sandbar.filter(i => item === i.name)[0].items;
            this.querySandbar = item
            this.queryCountry = null

            this.$emit('select', {
              type: 'country',
              data: {
                continent: this.querySandbar,
                country: this.queryCountry
              }
            })
        },

        pickCountry(country) {
            this.queryCountry = country

            this.$emit('select', {
              type: 'country',
              data: {
                continent: this.querySandbar,
                country: this.queryCountry
              }
            })
        },
        pickPartnerTopType(item) {
            this.partnersList = this.partners.filter(i => item === i.name)[0].items;
            this.queryTopPartnerType = item
            this.queryPartnerType = this.partnersList[0]

            this.$emit('select', {
              type: 'partner',
              data: {
                partnerType: this.queryTopPartnerType,
                specificType: this.queryPartnerType
              }
            })
        },
        pickPartnerType(type) {
            this.queryPartnerType = type

            this.$emit('select', {
              type: 'partner',
              data: {
                partnerType: this.queryTopPartnerType,
                specificType: this.queryPartnerType
              }
            })
        },
        //throttle  节流
        search: throttle(function () {
          let params = {
            "companyFullName": "",
            "coopCountry": "",
            "email": "",
            "partnerType": "",
            "region": "",
            "keyWords": this.keyWords,
            "status": true
          }
          this.$emit('submit', params);
        }, 300, {'trailing': true}),
        
    },
  };
  </script>
  
  <style scoped lang="less">
  
  
  .filter-area {
    margin: 20px 0px 20px;
    background: rgb(255, 255, 255);
    font-size: 14px;
    font-family: "Microsoft Yahei", sans-serif;
  
    .tag-group {
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;
  
      .tag-group-content {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        flex: 1;
        padding-right: 10px;
      }
    }
  
    .filter-area-content {
      border: 1px solid rgb(234, 238, 241);
      //padding: 0 15px;
    }
  
    label {
      margin: 0;
  
    }
  
    .filter-area-search {
      display: flex;
      align-items: center;
      justify-content: space-between;
      //padding: 15px 0;
      flex-wrap: wrap;
  
      ._active {
        color: var(--themeColor);
        //font-weight: 600;
      }
  
  
      .right-wrap {
        display: flex;
        align-items: center;
        max-width: 100%;
        flex: 1;
        justify-content: space-between;
  
        .search-bar {
          width: 90%;
          max-width: 1140px;
          background-color: #FFFFFF;
          height: 50px;
          border-radius: 48px;
          //display: flex;
          align-items: center;
          overflow: hidden;
          position: relative;
          z-index: 90;
          box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.5);
  
          .search-icon {
            font-size: 24px;
            margin: 12px -27px 0 0;
            //top:-30px;
            //float: left;
            right: 50px;
            color: #999999;
            position: absolute;
            z-index: 10;
          }
  
          .search-input {
            flex: 1;
            width: 100%;
            height: 50px;
            box-shadow: none;
            outline: none;
            font-size: 18px;
            padding-left: 60px;
            //margin-left: -55px;
            border-radius: 48px 0 0 48px;
            border: 1px solid transparent;
            position: relative;
            z-index: 3;
            color: #333333;
  
            &:focus + .search-icon {
              color: #c7000b;
            }
          }
  
          .search-button {
            cursor: pointer;
            color: #FFFFFF;
            font-size: 16px;
            background-color: var(--themeColor);
            height: 100%;
            width: 80px;
            padding-right: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;
  
            &:active {
              opacity: .8;
            }
          }
        }
  
        .total-num {
          padding: 10px 20px 10px 0;
          display: flex;
          align-items: center;
          position: relative;
          gap: 4px;
          white-space: nowrap;
        }
  
        .search-name-partner {
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0;
          line-height: 0;
          color: rgba(51, 51, 51, 1);
          text-align: left;
          //vertical-align: top;
        }
  
        .el-input {
          //  width: 360px;
          border-radius: 48px;
        }
  
        input::-webkit-input-placeholder {
          position: relative;
          left: 20px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 0px;
          color: rgba(153, 153, 153, 1);
          text-align: left;
          vertical-align: top;
        }
  
        /* Mozilla Firefox 4 to 18 适配火狐 */
  
        input:-moz-placeholder {
          position: relative;
          left: 20px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 0px;
          color: rgba(153, 153, 153, 1);
          text-align: left;
          vertical-align: top;
        }
  
        /* Mozilla Firefox 19+ 适配火狐 */
  
        input::-moz-placeholder {
          position: relative;
          left: 20px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 0px;
          color: rgba(153, 153, 153, 1);
          text-align: left;
          vertical-align: top;
        }
  
        /* Internet Explorer 10+  适配ie*/
  
        input:-ms-input-placeholder {
          position: relative;
          left: 20px;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 0px;
          color: rgba(153, 153, 153, 1);
          text-align: left;
          vertical-align: top;
        }
  
      }
    }
  
  
    .el-row {
      margin-top: 39px;
      margin-bottom: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  
    .el-col {
      border-radius: 4px;
    }
  
  
    .bg-purple {
      //background: #d3dce6;
      cursor: pointer;
    }
  
    .bg-purple-light {
      background: #e5e9f2;
    }
  
    .grid-content {
      display: flex; /*实现垂直居中*/
      align-items: center; /*实现水平居中*/
      //justify-content: center;
      text-align: justify;
      border-radius: 4px;
      min-height: 36px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 0px;
      color: rgba(51, 51, 51, 1);
    }
  
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }
  
    @media (max-width: 750px) {
      .tag-group {
        padding: 10px;
        margin-top: -20px;
      }
    }
  
    .ul-area {
      overflow: hidden;
      background-color: #f8f5f5;
      padding-left: 12px;
      color: rgba(51, 51, 51, 1);
  
      .sandbar {
        overflow: hidden;
  
        li {
          list-style: none;
          float: left;
          width: 120px;
          height: 33px;
          //line-height: 33px;
          text-align: center;
          font-size: 14px;
          border-radius: 25px;
          border: 1px solid #FFFFFF;
          background-color: #FFFFFF;
          padding: 6px 9px;
          margin-right: 10px;
          margin-top: 18px;
          margin-bottom: 16px;
        }
  
        li:hover, .filterSandbar_active {
          //background-color: #2b333f;
          cursor: pointer;
          color: #e7443f;
          border: 1px solid #e7443f;
        }
      }
  
      .country {
        overflow: hidden;
        padding-bottom: 17px;
  
        li {
          list-style: none;
          float: left;
          width: auto;
          height: 33px;
          //line-height: 33px;
          text-align: center;
          font-size: 14px;
          border-radius: 25px;
          border: 1px solid #2b542c;
          //background-color: #FFFFFF;
          padding: 6px 9px;
          margin-right: 20px;
          margin-top: 17px;
          //margin-bottom: 17px;
          border: 1px solid rgba(232, 232, 232, 1);
        }
  
        li:hover, .filterCountry_active {
          cursor: pointer;
          background-color: #e7443f;
          color: #f8f5f5;
          border: 1px solid #e7443f;
        }
      }
  
  
    }
  
    .partner-type {
      overflow: hidden;
      background-color: #f8f5f5;
      padding-left: 12px;
      color: rgba(51, 51, 51, 1);
  
      .partner-type-1 {
        overflow: hidden;
  
        li {
          list-style: none;
          float: left;
          width: auto;
          height: 33px;
          //line-height: 33px;
          text-align: center;
          font-size: 14px;
          border-radius: 25px;
          //border: 1px solid #2b542c;
          background-color: #FFFFFF;
          padding: 6px 9px;
          margin: 18px 10px 16px 120px;
          border: 1px solid #FFFFFF;
        }
  
        li:hover, .filterTopPartner_active {
          //background-color: #2b333f;
          cursor: pointer;
          color: #e7443f;
          border: 1px solid #e7443f;
        }
      }
  
      .partner-type-2 {
        overflow: hidden;
        padding-bottom: 17px;
  
        li {
          list-style: none;
          float: left;
          width: auto;
          height: 33px;
          //line-height: 33px;
          text-align: center;
          font-size: 14px;
          border-radius: 25px;
          //border: 1px solid #2b542c;
          //background-color: #FFFFFF;
          padding: 6px 9px;
          margin-right: 20px;
          margin-top: 17px;
          margin-left: 50px;
          //margin-bottom: 17px;
          border: 1px solid rgba(232, 232, 232, 1);
        }
  
        li:hover, .filterPartner_active {
          background-color: #e7443f;
          color: #f8f5f5;
          cursor: pointer;
          //border: 1px solid #e7443f;
        }
  
      }
  
  
    }
  
  }
  
  hr {
    border: 1px solid rgba(204, 204, 204, 1);
  }
  
  .delta {
    width: 0;
    height: 0;
    //margin: 20px auto;
    margin-left: 20px;
    border: 5px solid transparent;
    border-bottom-color: rgba(176, 176, 176, 1);
    transition: all 0.1s;
  }
  
  .delta—top {
    width: 0;
    height: 0;
    //margin: 20px auto;
    margin-left: 20px;
    border: 5px solid transparent;
    border-bottom-color: rgba(176, 176, 176, 1);
    transition: all 0.1s;
    transform: rotate(-180deg);
  }
  
  .expand {
    display: flex;
    justify-content: center;
    padding: 8px 0;
    cursor: pointer;
    align-items: center;
    gap: 4px;
    background-color: rgba(241, 244, 246, .4);
    margin: 0 -16px;
  }
  </style>
  